/* ./src/index.css */
@tailwind base;

@layer base {
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
  h4 {
    @apply text-lg;
  }
  a {
    @apply text-blue-600 underline;
  }
}
@tailwind components;
@tailwind utilities;
/* @import 'ckeditor-tailwind-reset/ckeditor-tailwind-reset'; */

html,
body {
  font-family: 'Varta', sans-serif;
}

@layer components {
  .resource-btn {
    @apply w-40 h-8 text-sm bg-gray-200 text-gray-700 border border-gray-300;
  }

  .resource-btn.active {
    @apply bg-primary text-white h-10 rounded-t-md;
  }

  .error-text {
    @apply text-red-600 font-semibold;
  }

  .input {
    @apply block border border-gray-300 w-full p-3 rounded mt-4;
  }

  .input.error {
    @apply border-red-600;
  }

  .sidebar-link {
    @apply text-secondary font-semibold tracking-wide hover:text-primary no-underline text-base;
  }

  .sidebar-link.active {
    @apply text-primary  text-lg;
  }

  #description {
    position: relative;
  }

  #description > ul {
    list-style: inside;
  }
  #description > ol {
    list-style-type: decimal;
  }

  ol,
  ul {
    padding: revert !important;
  }

  @media screen and (max-width: 768px) {
    iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
